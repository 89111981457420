import { NgModule } from "@angular/core";
import { ExtraOptions, RouterModule, Routes } from "@angular/router";

import { AuthGuardService } from "./auth-guard.service";
import { OnboardingGuardService } from "./backend-modules/onboarding/onboarding-guard.service";

const routes: Routes = [
  {
    path: "intro",
    loadChildren: () =>
      import("./intro/intro.module").then((m) => m.IntroModule),
  },

  {
    path: "moderation",
    loadChildren: () =>
      import("./backend-modules/moderation/moderation.module").then(
        (m) => m.ModerationModule,
      ),
    canActivate: [AuthGuardService],
  },

  {
    path: "admin",
    loadChildren: () =>
      import("./backend-modules/admin/admin.module").then((m) => m.AdminModule),
    canActivate: [AuthGuardService],
  },

  {
    path: "agency",
    loadChildren: () =>
      import("./backend-modules/agency/agency.module").then(
        (m) => m.AgencyModule,
      ),
    canActivate: [AuthGuardService],
  },

  {
    path: "auth",
    loadChildren: () =>
      import("./auth/auth.module").then((m) => m.NgxAuthModule),
  },

  {
    path: "sharing",
    loadChildren: () =>
      import("./sharing/sharing.module").then((m) => m.SharingModule),
  },

  {
    path: "integrations",
    loadChildren: () =>
      import("./backend-modules/integrations/integrations.module").then(
        (m) => m.IntegrationsModule,
      ),
  },
  {
    path: "onboarding",
    loadChildren: () =>
      import("./backend-modules/onboarding/onboarding.module").then(
        (m) => m.OnboardingModule,
      ),
    canActivate: [AuthGuardService, OnboardingGuardService],
  },

  {
    path: "",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
    canActivate: [AuthGuardService],
  },

  { path: "**", redirectTo: "" },
];

const config: ExtraOptions = {
  useHash: false,
  // relativeLinkResolution: "legacy",
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
