import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "environments/environment";
import { Observable } from "rxjs";

import { AccessService } from "./access.service";
import { CAuthService } from "./auth.service";

@Injectable()
export class AccessInterceptor implements HttpInterceptor {
  constructor(
    private accessService: AccessService,
    private auth: CAuthService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // Skip API
    const isApi =
      req.url.startsWith(environment.APIv1Endpoint) ||
      req.url.startsWith(environment.APIv2Endpoint);
    if (!isApi) {
      return next.handle(req);
    }

    let newHeaders = req.headers;

    const t = this.auth.token();
    if (t) {
      newHeaders = newHeaders.set("Authorization", `Bearer ${t}`);
    }

    if (!this.accessService.current) {
      return next.handle(
        req.clone({
          headers: newHeaders,
        }),
      );
    }

    const current = this.accessService.current;

    newHeaders = newHeaders.set("Company", current.company.id.toString());
    if (current.account) {
      newHeaders = newHeaders.set("Account", current.account.id.toString());
    }

    return next.handle(
      req.clone({
        headers: newHeaders,
      }),
    );
  }
}
