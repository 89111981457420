import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";

import { Me } from "@core/api-models/users.models";
import { UsersService } from "@core/api/users.service";
import { AccessSelection, AccessService } from "@core/services/access.service";
import { CAuthService } from "@core/services/auth.service";
import { ProctorService } from "@core/services/proctor.service";
import { UserService } from "@core/services/user.service";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService {
  DEBUG = false;

  constructor(
    private router: Router,
    private usersService: UsersService,
    private proctorService: ProctorService,
    private accessService: AccessService,
    private us: UserService,
    private auth: CAuthService,
  ) {}

  debugLog(s) {
    if (!this.DEBUG) {
      return;
    }
    // eslint-disable-next-line no-restricted-syntax
    console.log(s);
  }

  navigatePath(path: string) {
    const [cleanPath, rawParams] = path.split("?");
    const params = Object.fromEntries(new URLSearchParams(rawParams));
    this.router.navigate([cleanPath], {
      queryParams: { ...params, account: null, path: null, company: null },
      queryParamsHandling: "merge",
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const whatToDo = new Observable<{
      authenticated: boolean;
      user?: Me;
      accessIsValid?: boolean;
      access?: AccessSelection;
    }>((observer) => {
      if (!this.auth.isAuthenticated()) {
        observer.next({ authenticated: false });
        return;
      }

      this.us.me().subscribe({
        next: (me) => {
          this.proctorService.initProctor(me);

          const company = parseInt(route.queryParamMap.get("company"));
          const isProctor = this.us.checkUserHasPermission(me, "proctor");
          if (company > 0 && isProctor) {
            this.proctorService.changeCompany({
              id: company,
              name: "",
            });
          }

          this.usersService.getUserAccess().subscribe({
            next: (access) => {
              this.accessService.updateAvailable(access, false);

              const account = route.queryParamMap.get("account");
              const path = route.queryParamMap.get("path");

              if (account) {
                const availCompanies = this.accessService.available.filter(
                  (c) => c.accounts.some((a) => a.id === parseInt(account)),
                );

                if (availCompanies.length > 0) {
                  const act = availCompanies[0].accounts.find(
                    (a) => a.id === parseInt(account),
                  );

                  const c = availCompanies[0];
                  this.proctorService.changeCompany({
                    id: c.id,
                    name: c.name,
                  });

                  this.accessService.switchAccess(c, act, false);
                }
              }
              if ((company || account) && path) {
                this.navigatePath(path);
                return;
              }

              observer.next({
                authenticated: true,
                user: me,
                accessIsValid: this.accessService.validateCurrentAccess(),
                access: this.accessService.current,
              });
            },
            error: (err) => observer.error(err),
          });
        },
        error: (err) => {
          if (err.status === 401) {
            this.auth.logout();
            return;
          }
          observer.error(err);
        },
      });
    });

    return whatToDo.pipe(
      tap((facts) => {
        const targetURL = state.url.toLowerCase();

        const authenticated = facts["authenticated"];

        if (!authenticated) {
          this.router.navigate(["auth/login"]);
          return;
        }

        const permissionsTable = facts.user["permissions"] ?? {};

        const userPermissions = new Set(
          Object.keys(permissionsTable).filter(
            (k) => permissionsTable[k] === true,
          ),
        );

        const moderationOnly =
          userPermissions.size === 1 && userPermissions.has("moderator");

        const goingToAdmin = targetURL.startsWith("/admin");
        const goingToAgency = targetURL.startsWith("/agency");

        const goingToOnboarding = targetURL.startsWith("/onboarding");

        // Allow admins to admin
        if (userPermissions.has("admin") && goingToAdmin) {
          this.debugLog("Valid admin, going there now");
          return;
        }
        // Non admin going to admin is not allowed
        else if (goingToAdmin) {
          this.debugLog("Block from going to admin, not allowed");
          this.router.navigate(["/"]);
          return;
        }

        if (!facts["accessIsValid"] && !(goingToAgency || goingToAdmin)) {
          this.debugLog("Access is invalid, to account");
          this.debugLog(this.accessService.available);

          let path = route.queryParamMap.get("path");

          if (!path) {
            path = state.url === "/home" ? null : state.url;
          }

          this.router.navigate(["intro", "account-switcher"], {
            queryParams: { path: path },
          });
          return;
        }

        // Moderators can only see /moderation
        if (moderationOnly && !targetURL.startsWith("/moderation")) {
          this.router.navigate(["moderation"]);
          this.debugLog("MOD");
          return;
        }

        // Only moderators and admins can see this, redirect any other type
        if (
          !userPermissions.has("moderator") &&
          targetURL.startsWith("/moderation")
        ) {
          this.router.navigate(["/"]);
          this.debugLog("MOD2");
          return;
        }

        // If onboarding is not done on either company or account we onboard them, but proctors move around it
        if (facts?.access?.account != null) {
          if (
            !facts?.access.account.onboarding_done &&
            !userPermissions.has("proctor")
          ) {
            if (!goingToOnboarding) {
              this.debugLog("Should go to onboarding!!!");
              this.router.navigate(["/onboarding"]);
            }
            return;
          }
        }

        // IMPORTANT:
        // We can only check the access after determining whether we actually have valid access
        // Otherwise we will have redirected to account switcher by now.
        // const company = facts.access?.company;

        // No longer forces payment right now
        // if (company) {
        // const companyPermissionsTable = company.permissions;
        // const companyPermissions = new Set(
        //   Object.keys(companyPermissionsTable).filter(
        //     (k) => companyPermissionsTable[k] === true
        //   )
        // );

        // const hasPayment = company.has_payment_method;
        // const isDemo = companyPermissions.has("demo_mode");

        // if (
        //   !hasPayment &&
        //   !this.proctorService.info.proctorModeActive() &&
        //   !(
        //     // These allow the user to skip adding a card
        //     (
        //       companyPermissions.has("allow_no_payment") ||
        //       companyPermissions.has("yearly_manual_invoicing") ||
        //       (facts.user.agency && isDemo)
        //     ) // Agency users skip this too if company is a demo
        //   )
        // ) {
        //   this.router.navigate(["intro/payment"]);
        //   return;
        // } else if (hasPayment && targetURL.startsWith("/intro/payment")) {
        //   this.router.navigate(["/"]);
        //   return;
        // }
        // }

        // Anon users only have access to very few places
        if (facts.user.anon === true) {
          // If path is not whitelisted, go to start
          const ANON_WHITELIST = [
            "/designs/new",
            "/designs/edit",
            "/designs/demo",
          ];

          if (!ANON_WHITELIST.some((p) => targetURL.startsWith(p))) {
            this.router.navigate(["/designs/demo"]);
            return;
          }
        }

        this.debugLog("DONE");
      }),
      map((x) => {
        return x["authenticated"];
      }),
    );
  }
}
