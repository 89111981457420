import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ApiService } from "@core/api.service";

import { environment } from "environments/environment";
import { Observable } from "rxjs";

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  constructor(private api: ApiService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // Skip API
    const isApi =
      req.url.startsWith(environment.APIv1Endpoint) ||
      req.url.startsWith(environment.APIv2Endpoint);
    if (!isApi || !this.api.session) {
      return next.handle(req);
    }

    const newReq = req.clone({
      headers: req.headers.set("Confect-Session", this.api.session.toString()),
    });

    return next.handle(newReq);
  }
}
